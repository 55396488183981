const anchors = document.querySelectorAll('div[href*="#"]')
const header = document.getElementsByTagName('header')[0];

for (let anchor of anchors) {
    anchor.addEventListener('click', function (e) {
        e.preventDefault()

        const blockID = anchor.getAttribute('href').substr(1)

        window.scrollTo({top: document.getElementById(blockID).offsetTop - header.getBoundingClientRect().bottom, behavior: 'smooth'});
    })
}

const body = document.querySelector('body');

body.onscroll = (e) => {
    if (window.scrollY >= document.documentElement.clientHeight/4) {
        header.classList.add("active");
    } else {
        header.classList.remove("active");
    }
};

//If you want to use JQuery:

// import $ from 'jquery';

// $(document).ready(function () {
//     //main code goes here
// });

//OR

//If you want to write on native JS:

// document.addEventListener("DOMContentLoaded", function(event) {
//     //main code goes here
// });


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
    apiKey: "AIzaSyDM98Hy36e31o3x_m9J6dfsNYsV9vgbwhc",
    authDomain: "protex-site.firebaseapp.com",
    databaseURL: "https://protex-site-default-rtdb.firebaseio.com",
    projectId: "protex-site",
    storageBucket: "protex-site.appspot.com",
    messagingSenderId: "871657270433",
    appId: "1:871657270433:web:69f97b26f4a4dad90dd4e0",
    measurementId: "G-87BTQW90MD"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
var db = firebase.firestore();

const element = document.querySelector('form');
element.addEventListener('submit', event => {
    event.preventDefault();
    const values = new FormData(document.forms.feedbackForm);
    const data = {
        fullName: values.get("fullName"),
        email: values.get("email"),
        companyName: values.get("companyName"),
        phone: values.get("phone"),
        dateAdded: new Date(),
    }
    firebase.database().ref('feedback').push().set(data)
    db.collection("feedback")
        .add(data)
        .then((docRef) => {
            console.log("Document written with ID: ", docRef.id);
            document.forms.feedbackForm.reset();
            document.querySelector('.footer__welcome').style.display = 'flex';
            document.querySelector('.footer__container').style.display = 'none';
        })
        .catch((error) => {
            console.error("Error adding document: ", error);
        })
});
onWelcome = () => {
    document.querySelector('.footer__container').style.display = 'flex';
    document.querySelector('.footer__welcome').style.display = 'none';
}